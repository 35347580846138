<template>
  <intervenants :type="'invite'">
    Aucun invités n'est encore annoncés pour cette édition.
  </intervenants>
</template>

<script>
import Intervenants from "./Intervenant/Intervenants";

export default {
  name: "Invites",
  components: {Intervenants},
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
</style>
